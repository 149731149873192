import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './routes/Home';
import Contact from './routes/Contact';
import About from './routes/About';
import Professional from './routes/Professional';
import Personal from './routes/Personal';
import Education from './routes/Education';
import Experience from './routes/Experience';
import Resume from './routes/Resume';
import Project from './routes/Project';

function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/professional" element={<Professional />} />
      <Route path="/personal" element={<Personal />} />
      <Route path="/education" element={<Education />} />
      <Route path="/experience" element={<Experience/>} />
      <Route path="/resume" element={<Resume/>} />
      <Route path="/project" element={<Project/>} />
    </Routes>
    </>
  );
}

export default App;
