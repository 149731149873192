import "./NestedStyles.css";

import React from 'react'
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <div className="header">
            <Link to="/">
            <h1></h1>
            </Link>
            <ul className="nav-menu"> 
            <li>
                <Link to="/education">Education</Link>
            </li>
            <li>
                <Link to="/experience">Experience</Link>
            </li>
            <li>
                <Link to="/project">Project</Link>
            </li>
             <li>
                <Link to="/resume">Resume</Link>
             </li>
        </ul>
    </div>
  )
}

export default NavBar;