import React from 'react'
import Navbar from '../components/Navbar'

const Personal = () => {
  return <>
  <div>
      <Navbar/>
      <div className='temp'>Personal</div>
      <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40vh',
      }}
    >
      <h2>Website under production. 
            Check back later,
            Thank you for your patience.</h2>
    </div>
    <h2>Enquiries: mail@narinderpal.com</h2>
      </div>
      </>
}

export default Personal